<template>
  <div class="home">
    <div class="carousel_container">
      <ul class="background_mask" v-if="ishow">
        <li class="mask1"></li>
        <li class="mask2"></li>
        <li class="mask3"></li>
        <li class="mask4"></li>
        <li class="mask5"></li>
      </ul>
      <swiper :options="swiperOption" class="home_swiper">
        <swiper-slide class="slide_title1">
          <div class="title_content container_width">
            <div class="sub_title">The leader who wins in effectiveness</div>
            <h1>赢在实效的领航者</h1>
            <div class="introduce">
              我们是贤牛。无论顺境还是逆境，我们始终与品牌携手前行，为企业和社会传递增长和向善的力量。我们是乐观主义者，企业家和先行者，我们的团队能<br />够结合客户的需求，为实现可持续价值和持久变革开创先河。
            </div>
            <a class="a_button carousel_button_animation" @click="scrollTo(0)"
              >即刻开始</a
            >
          </div>
        </swiper-slide>
        <swiper-slide class="slide_title2">
          <div class="title_content container_width">
            <div class="sub_title">The leader who wins in effectiveness</div>
            <h1>赢在实效的领航者</h1>
            <div class="introduce">
              我们是贤牛。无论顺境还是逆境，我们始终与品牌携手前行，为企业和社会传递增长和向善的力量。我们是乐观主义者，企业家和先行者，我们的团队能<br />够结合客户的需求，为实现可持续价值和持久变革开创先河。
            </div>
            <a class="a_button carousel_button_animation" @click="scrollTo(0)"
              >即刻开始</a
            >
          </div>
        </swiper-slide>
        <swiper-slide class="slide_title3">
          <div class="title_content container_width">
            <div class="sub_title">The leader who wins in effectiveness</div>
            <h1>赢在实效的领航者</h1>
            <div class="introduce">
              我们是贤牛。无论顺境还是逆境，我们始终与品牌携手前行，为企业和社会传递增长和向善的力量。我们是乐观主义者，企业家和先行者，我们的团队能<br />够结合客户的需求，为实现可持续价值和持久变革开创先河。
            </div>
            <a class="a_button carousel_button_animation" @click="scrollTo(0)"
              >即刻开始</a
            >
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination homeswp"></div>
      <div class="home-swiper-button-prev homearrow">
        <img src="../assets/imgs/home/home_swiper_prev.webp" alt="" />
      </div>
      <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
      <div class="home-swiper-button-next homearrow">
        <img src="../assets/imgs/home/home_swiper_next.webp" alt="" />
      </div>
      <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
    </div>
    <div class="card_container">
      <div class="card_box anchor_h2 border_text_box container_width">
        <h2 class="border_text">Drive meaningful progress</h2>
        <h3 class="border_text_title">推动有意义的进步</h3>
        <div
          class="card_introduce"
          v-bind:class="{ text_bottom_in: textanimationflag }"
        >
          我们是贤牛。无论好坏，我们都与品牌合作，以实现有意义的进步，作为增长和良好的力量。我们的乐观主义者、企业家和先行者团队围绕客户的需求联合起来，为可持续价值和持久变革开启独特的可能性。以消费者智能为我们所做的一切的核心，我们帮助品牌在营销组合的所有元素中赢得、保持和发展他们的最佳客户。通过激进的合作，我们将洞察力变为现实，推动业务向前发展。
        </div>
        <ul class="card">
          <li v-bind:class="{ card_bottom_in1: cardlistanimationflag }">
            <div class="header_card_img img_1"></div>
            <div class="card_title">创新</div>
            <div class="card_introduction">
              领导力是要勇于创新并经常接受新挑战，而不是停留在你的舒适区里。
            </div>
          </li>
          <li v-bind:class="{ card_bottom_in2: cardlistanimationflag }">
            <div class="header_card_img img_2"></div>
            <div class="card_title">长远性</div>
            <div class="card_introduction">
              领导力是要有长远性，而不只是一闪即过的短期成功。
            </div>
          </li>
          <li v-bind:class="{ card_bottom_in3: cardlistanimationflag }">
            <div class="header_card_img img_3"></div>
            <div class="card_title">挑战</div>
            <div class="card_introduction">
              领导力是要面对并解决每一次挑战、无惧犯错并从中吸取教训、相应地调整您的计划并永不退让。
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="learn_container">
      <div class="learn_box container_width">
        <div
          class="learn_left"
          v-bind:class="{ learntext_in: learnswiperflag }"
        >
          <div class="left_title">我们比任何人</div>
          <div class="left_title text_bold">都更了解人</div>
          <div class="left_introduce">
            以消费者智能为我们所做的一切的核心，我们帮助品牌在营销组合的所有元素中赢得、保持和发展他们的最佳客户。
          </div>
        </div>
        <div
          class="learn_right"
          v-bind:class="{ LearnSwiper_in: learnswiperflag }"
        >
          <div class="learn-swiper-pagination learnswp"></div>
          <swiper :options="LearnSwiperOption" class="learn_swiper">
            <swiper-slide>
              <div class="learn_swiper_container">
                <span
                  >我们不人云亦云，我们引领科技营销，在最绝望的岁月里，业绩就是我们的唯一，熬过了那些难熬的苦，才能熬更难的苦，这就是生活。领导力是要有长远性，而不只是一闪即过的短期成功。</span
                >
              </div>
            </swiper-slide>
            <swiper-slide>Slide 2</swiper-slide>
            <swiper-slide>Slide 3</swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="brand_container">
      <div class="brand_box container_width">
        <div
          class="brand_left"
          v-bind:class="{ brand_title_in: brandtitleflag }"
        >
          <div class="brand_title">{{ year }}聚焦未来-品牌加速</div>
          <div class="brand_introduce">
            现今世界人们的联系变得空前紧密。科技让我们与更多
            人、更多社区连接起来。科技让我们坐在家中即可轻松连
            接几乎无穷无尽的娱乐、信息和教育内容。科技让我们轻
            轻一点，即可连接交通、外卖和购物等服务。科技实现城
            市和家居联网，更高效地满足我们的需求。连接水平的不
            断提升为人们和品牌解锁了新的繁荣发展空间。
          </div>
          <a href="javascript:;" class="a_button">查看全部</a>
        </div>
        <div
          class="brand_right"
          v-bind:class="{ LearnSwiper_in: brandtitleflag }"
        >
          <img src="../assets/imgs/home/brand_right_1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="project_container">
      <div class="project_box border_text_box">
        <h2 class="border_text">Project summary</h2>
        <h3 class="border_text_title">项目汇总</h3>
      </div>
      <div class="server_swiper_box">
        <swiper :options="ProjectSwiperOption" class="project_swiper">
          <swiper-slide>
            <img src="../assets/imgs/home/project_swiper_1.png" alt="" />
            <div class="server_introduce_box">
              <div class="title">全自动广告投放系统</div>
              <div class="content">
                这是一个企业级的开箱即用的爬虫管理平台，包含很多针对爬虫调度监控的实用功能，例如节点注册发现...
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/imgs/home/project_swiper_2.png" alt="" />
            <div class="server_introduce_box">
              <div class="title">全自动广告投放系统</div>
              <div class="content">
                这是一个企业级的开箱即用的爬虫管理平台，包含很多针对爬虫调度监控的实用功能，例如节点注册发现...
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/imgs/home/project_swiper_3.png" alt="" />
            <div class="server_introduce_box">
              <div class="title">全自动广告投放系统</div>
              <div class="content">
                这是一个企业级的开箱即用的爬虫管理平台，包含很多针对爬虫调度监控的实用功能，例如节点注册发现...
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/imgs/home/project_swiper_4.png" alt="" />
            <div class="server_introduce_box">
              <div class="title">全自动广告投放系统</div>
              <div class="content">
                这是一个企业级的开箱即用的爬虫管理平台，包含很多针对爬虫调度监控的实用功能，例如节点注册发现...
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/imgs/home/project_swiper_2.png" alt="" />
            <div class="server_introduce_box">
              <div class="title">全自动广告投放系统</div>
              <div class="content">
                这是一个企业级的开箱即用的爬虫管理平台，包含很多针对爬虫调度监控的实用功能，例如节点注册发现...
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="server-swiper-button-prev serverswip">
          <div class="left"></div>
        </div>
        <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
        <div class="server-swiper-button-next serverswip">
          <div class="right"></div>
        </div>
        <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
      </div>
      <!-- <div class="project_box auout_box container_width">
          <div class="project_text">本能的慷慨是引导我们的东西。这就是为什么我们开展研究和分析来引领行业辩论的原因。想要更了解我们的团队吗？</div>
          <div class="a_colorless_button"><a href="javascript:;">关于我们</a></div>
        </div> -->
    </div>
    <service-card></service-card>
    <contact-us></contact-us>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "../assets/scss/animation.scss";
import "../assets/scss/communal.scss";
import ServiceCard from "@/components/ServiceCard";
import ContactUs from "@/components/ContactUs";
export default {
  name: "debt",
  components: {
    Swiper,
    SwiperSlide,
    ServiceCard,
    ContactUs,
  },
  data() {
    return {
      ishow: true,
      homeswiperanimation: 1,
      textanimationflag: false,
      cardlistanimationflag: false,
      learnswiperflag: false,
      brandtitleflag: false,
      brandconterflag: false,
      // 轮播图设置
      swiperOption: {
        loop: true,
        pagination: {
          el: ".swiper-pagination.homeswp",
          clickable: true,
        },
        navigation: {
          nextEl: ".home-swiper-button-next.homearrow",
          prevEl: ".home-swiper-button-prev.homearrow",
        },
        on: {
          slideChange: function () {
            var swiper1 = document.getElementsByClassName("slide_title1");
            var swiper2 = document.getElementsByClassName("slide_title2");
            var swiper3 = document.getElementsByClassName("slide_title3");
            if (this.activeIndex === 1 || this.activeIndex === 4) {
              swiper1[0].classList.add("slide_title1_animation");
              swiper1[1].classList.add("slide_title1_animation");
              swiper2[0].classList.remove("slide_title2_animation");
              swiper3[0].classList.remove("slide_title3_animation");
              swiper3[1].classList.remove("slide_title3_animation");
            }
            if (this.activeIndex === 2) {
              swiper1[0].classList.remove("slide_title1_animation");
              swiper1[1].classList.remove("slide_title1_animation");
              swiper2[0].classList.add("slide_title2_animation");
              swiper3[0].classList.remove("slide_title3_animation");
              swiper3[1].classList.remove("slide_title3_animation");
            }
            if (this.activeIndex === 3 || this.activeIndex === 0) {
              swiper1[0].classList.remove("slide_title1_animation");
              swiper1[1].classList.remove("slide_title1_animation");
              swiper2[0].classList.remove("slide_title2_animation");
              swiper3[0].classList.add("slide_title3_animation");
              swiper3[1].classList.add("slide_title3_animation");
            }
          },
        },
      },
      LearnSwiperOption: {
        loop: true,
        pagination: {
          el: ".learn-swiper-pagination.learnswp",
          clickable: true,
        },
      },
      ProjectSwiperOption: {
        spaceBetween: 0,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".server-swiper-button-prev.serverswip",
          prevEl: ".server-swiper-button-next.serverswip",
        },
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.ulishow();
  },
  destroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const navContents = document.querySelectorAll(".anchor_h2");
      const offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop);
      });
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let navIndex = 0;
      if (scrollTop > 300) {
        this.textanimationflag = true;
      }
      if (scrollTop > 700) {
        this.cardlistanimationflag = true;
      }
      if (scrollTop > 1500) {
        this.learnswiperflag = true;
      }
      if (scrollTop > 2000) {
        this.brandtitleflag = true;
      }
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    scrollTo(index) {
      const targetOffsetTop =
        document.querySelectorAll(`.anchor_h2`)[index].offsetTop - 150;
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const STEP = 50;
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      function smoothDown() {
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    },
    ulishow() {
      setTimeout(() => {
        this.ishow = false;
      }, 2000);
    },
  },
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";
@import "../assets/iconfont/iconfont.css";
.home {
  .carousel_container {
    margin-bottom: 120px;
    position: relative;
    background: #000;
    .home_swiper {
      .slide_title1 {
        background: url("../assets/imgs/home/home_swiper_1.png") 50% 50%;
        background-size: cover;
      }
      .slide_title2 {
        background: url("../assets/imgs/home/home_swiper_2.png") 50% 50%;
        background-size: cover;
      }
      .slide_title3 {
        background: url("../assets/imgs/home/home_swiper_3.png") 50% 50%;
        background-size: cover;
      }
      .slide_title1_animation {
        .title_content {
          .sub_title {
            -webkit-animation: fade-in 1.2s;
            animation: fade-in 1.2s;
            animation-delay: 1.5s;
            animation-fill-mode: both;
          }
          h1 {
            -webkit-animation: fade-in-bottom 1;
            animation: fade-in-bottom 1s;
            animation-delay: 1s;
            animation-fill-mode: both;
          }
          .introduce {
            -webkit-animation: fade-in 1.2s;
            animation: fade-in 1.2s;
            animation-delay: 1.5s;
            animation-fill-mode: both;
          }
          .carousel_button_animation {
            -webkit-animation: scale-in-hor-left 0.5s
              cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation: scale-in-hor-left 0.5s
              cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-delay: 1.5s;
            animation-fill-mode: both;
          }
        }
      }
      .slide_title2_animation {
        .title_content {
          .sub_title {
            -webkit-animation: fade-in 1.2s;
            animation: fade-in 1.2s;
            animation-delay: 1.5s;
            animation-fill-mode: both;
          }
          h1 {
            -webkit-animation: fade-in-bottom 1;
            animation: fade-in-bottom 1s;
            animation-delay: 0.6s;
            animation-fill-mode: both;
          }
          .introduce {
            -webkit-animation: fade-in 1.2s;
            animation: fade-in 1.2s;
            animation-delay: 1.5s;
            animation-fill-mode: both;
          }
          .carousel_button_animation {
            -webkit-animation: scale-in-hor-left 0.5s
              cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation: scale-in-hor-left 0.5s
              cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 1.5s;
          }
        }
      }
      .slide_title3_animation {
        .title_content {
          .sub_title {
            -webkit-animation: fade-in 1.2s;
            animation: fade-in 1.2s;
            animation-delay: 1.5s;
            animation-fill-mode: both;
          }
          h1 {
            -webkit-animation: fade-in-bottom 1;
            animation: fade-in-bottom 1s;
            animation-delay: 0.6s;
            animation-fill-mode: both;
          }
          .introduce {
            -webkit-animation: fade-in 1.2s;
            animation: fade-in 1.2s;
            animation-delay: 1.5s;
            animation-fill-mode: both;
          }
          .carousel_button_animation {
            -webkit-animation: scale-in-hor-left 0.5s
              cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation: scale-in-hor-left 0.5s
              cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 1.5s;
          }
        }
      }
      .title_content {
        text-align: center;
        margin: 250px auto 400px;
        color: #fff;
        font-size: 14px;
        .sub_title {
          opacity: 0;
          font-size: 24px;
        }
        h1 {
          opacity: 0;
          font-size: 80px;
          font-weight: 800;
          margin-bottom: 30px;
        }
        .introduce {
          opacity: 0;
          margin-bottom: 100px;
        }
        .a_button {
          opacity: 0;
        }
      }
    }
    .homeswp {
      @include flexrl();
      position: absolute;
      bottom: 95px;
      left: 50%;
      transform: translateX(-50%);
      .swiper-pagination-bullet {
        width: 11px;
        height: 11px;
        border: 1px solid #ffffff;
        margin: 0 45px;
        opacity: 1;
        background: none;
      }
      .swiper-pagination-bullet-active {
        background: #fff;
        border: 1px #cfcfcf solid;
      }
    }
    .homearrow {
      @include flexc();
      width: 75px;
      height: 75px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
      position: absolute;
      z-index: 2;
      img {
        width: 50px;
      }
    }
    .home-swiper-button-prev {
      left: 50px;
      top: 50%;
      transform: translateY(-50%);
    }
    .home-swiper-button-next {
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
    }
    .background_mask {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      li {
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 3;
      }
      .mask1 {
        -webkit-animation: slide-in-top 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-fill-mode: forwards;
      }
      .mask2 {
        -webkit-animation: slide-in-top 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-fill-mode: forwards;
        animation-delay: 0.15s;
      }
      .mask3 {
        -webkit-animation: slide-in-top 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;
      }
      .mask4 {
        -webkit-animation: slide-in-top 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-fill-mode: forwards;
        animation-delay: 0.45s;
      }
      .mask5 {
        -webkit-animation: slide-in-top 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-fill-mode: forwards;
        animation-delay: 0.6s;
      }
    }
  }
  .card_container {
    .card_box {
      text-align: center;
      .card_introduce {
        opacity: 0;
        font-size: 16px;
        text-align: center;
        color: #464949;
        line-height: 24px;
        animation-fill-mode: both;
      }
      .card {
        display: flex;
        margin: 120px 0 220px;
        color: #161616;
        justify-content: space-between;
        z-index: 99;
        li {
          opacity: 0;
          width: 30%;
          display: flex;
          align-items: center;
          flex-direction: column;
          border-radius: 20px;
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
          animation-fill-mode: both;
          .header_card_img {
            width: 76px;
            height: 80px;
            margin-top: 40px;
          }
          .img_1 {
            background: url("../assets/imgs/home/home_card_1.png") 50% 50%;
            background-size: cover;
          }
          .img_2 {
            background: url("../assets/imgs/home/home_card_2.png") 50% 50%;
            background-size: cover;
          }
          .img_3 {
            background: url("../assets/imgs/home/home_card_3.png") 50% 50%;
            background-size: cover;
          }
          .card_title {
            margin: 20px 0;
            font-size: 24px;
            font-weight: 700;
          }
          .card_introduction {
            width: 80%;
            margin-bottom: 70px;
          }
        }
        li:hover {
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        }
        li:first-child:hover > .img_1 {
          background: url("../assets/imgs/home/home_card_1.gif") 50% 50%;
        }
        li:nth-child(2):hover > .img_2 {
          background: url("../assets/imgs/home/home_card_2.gif") 50% 50%;
        }
        li:last-child:hover > .img_3 {
          background: url("../assets/imgs/home/home_card_3.gif") 50% 50%;
        }
      }
    }
  }
  .learn_container {
    background: #f7f7f7;
    .learn_box {
      @include flexc();
      padding: 140px 0;
      .learn_left {
        width: 50%;
        opacity: 0;
        position: relative;
        margin-right: 70px;
        animation-fill-mode: both;
        .left_title {
          font-size: 48px;
          text-align: left;
          color: #161616;
        }
        .left_introduce {
          font-size: 16px;
          text-align: justify;
          color: #464949;
          line-height: 24px;
          margin-top: 20px;
        }
      }
      .learn_left::after {
        content: "“";
        position: absolute;
        -webkit-text-stroke: 1px #c5c5c5;
        font-size: 128px;
        color: #f7f7f7;
        top: -60px;
        left: -110px;
      }
      .learn_right {
        width: 40%;
        opacity: 0;
        animation-fill-mode: both;
        .learnswp {
          @include flexrowright();
          margin-bottom: 15px;
          .swiper-pagination-bullet {
            width: 11px;
            height: 11px;
            border: 1px solid #464949;
            background: none;
          }
          .swiper-pagination-bullet:not(:first-of-type):not(:last-of-type) {
            margin: 0 15px;
          }
          .swiper-pagination-bullet-active {
            background: #000;
          }
        }
        .learn_swiper {
          .learn_swiper_container {
            opacity: 0.6;
            background: url("../assets/imgs/home/learn_swiper_1.png") no-repeat
              50% 50%;
            color: #fff;
            padding: 67px 50px;
          }
        }
      }
    }
  }
  .brand_container {
    overflow: hidden;
    .brand_box {
      width: 84%;
      float: right;
      @include flexrl();
    }
    .brand_left {
      padding-right: 45px;
      width: 36%;
      opacity: 0;
      animation-fill-mode: both;
      .brand_title {
        font-size: 48px;
        font-weight: bold;
        text-align: left;
        color: #161616;
        line-height: 48px;
        margin-bottom: 40px;
      }
      .brand_introduce {
        margin-bottom: 50px;
        font-size: 16px;
        text-align: justify;
        color: #464949;
        line-height: 24px;
        letter-spacing: -0.03px;
      }
    }
    .brand_right {
      width: 65%;
      opacity: 0;
      animation-fill-mode: both;
      img {
        width: 100%;
      }
    }
  }
  .project_container {
    margin-top: 85px;
    .project_box {
      text-align: center;
    }
    .server_swiper_box {
      position: relative;
      .project_swiper {
        .swiper-slide {
          height: 480px;
          position: relative;
          width: auto;
          overflow: hidden;
          img {
            cursor: pointer;
            transition: all 0.5s;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          img:hover {
            transform: scale(1.5);
          }
          img:hover + .server_introduce_box {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 0.8;
            z-index: 2;
          }
          .server_introduce_box {
            position: absolute;
            opacity: 0;
            background: #000000;
            bottom: 0;
            color: #fff;
            text-align: center;
            padding: 10px 50px 20px;
            transition: all 0.5s;
            -webkit-transform: translateY(100px);
            transform: translateY(100px);
            z-index: -1;
            .title {
              margin-bottom: 20px;
              font-size: 20px;
              font-weight: bold;
              line-height: 36px;
              letter-spacing: -0.06px;
            }
            .content {
              font-size: 15px;
              color: #efefef;
              line-height: 30px;
              letter-spacing: -0.03px;
            }
          }
        }
      }
      .serverswip {
        width: 61px;
        height: 61px;
        background: #ffffff;
      }
      .server-swiper-button-prev {
        position: absolute;
        top: 50%;
        left: 0px;
        z-index: 2;
        @include flexc();
        .left {
          width: 10px;
          height: 10px;
          border-top: 3px solid #1b1c31;
          border-left: 3px solid #1b1c31;
          transform: rotate(-45deg);
        }
      }
      .server-swiper-button-next {
        position: absolute;
        top: 50%;
        right: 0px;
        z-index: 2;
        @include flexc();
        .right {
          width: 10px;
          height: 10px;
          border-top: 3px solid #1b1c31;
          border-left: 3px solid #1b1c31;
          transform: rotate(135deg);
        }
      }
    }
    .auout_box {
      @include flexrl();
      margin: 100px auto;
      .project_text {
        font-size: 16px;
        text-align: left;
        color: #464949;
        line-height: 24px;
      }
    }
  }
}
// 推动有意义的进步标题卡片动画
.text_bottom_in {
  -webkit-animation: fadeIn 1.6s;
  animation: fadeIn 1.6s;
  animation-fill-mode: both;
}
.card_bottom_in1 {
  -webkit-animation: news-title 1.6s;
  animation: news-title 1.6s;
  animation-fill-mode: both;
}
.card_bottom_in2 {
  -webkit-animation: news-title 1.6s;
  animation: news-title 1.6s;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}
.card_bottom_in3 {
  -webkit-animation: news-title 1.6s;
  animation: news-title 1.6s;
  animation-delay: 0.8s;
  animation-fill-mode: both;
}
// 轮播图进场动画
.LearnSwiper_in {
  -webkit-animation: fade-in-right 1.6s;
  animation: fade-in-right 1.6s;
  animation-fill-mode: both;
}
.learntext_in {
  -webkit-animation: fade-in-left 1.6s;
  animation: fade-in-left 1.6s;
  animation-fill-mode: both;
}
// 品牌模块进场动画
.brand_title_in {
  -webkit-animation: fade-in-bottom-custom 1.6s;
  animation: fade-in-bottom-custom 1.6s;
  animation-fill-mode: both;
}
@media screen and (max-width: 1160px) {
  .home {
    .carousel_container {
      .homearrow {
        display: none;
      }
    }
    .brand_container {
      .brand_box {
        width: 94%;
      }
      .brand_left {
        .brand_title {
          font-size: 29px;
          margin-bottom: 10px;
        }
        .brand_introduce {
          margin-bottom: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .home {
    .carousel_container {
      .background_mask {
        li:nth-child(2n) {
          display: none;
        }
      }
    }
    .brand_container {
      padding: 20px 0 0 0;
      .brand_box {
        flex-direction: column;
        width: 100%;
        .brand_left {
          width: 80%;
          text-align: center;
          margin-bottom: 20px;
          padding-right: 0px;
          .brand_title {
            text-align: center;
          }
          .brand_introduce {
            text-align: center;
          }
        }
        .brand_right {
          width: 100%;
        }
      }
    }
    .learn_container {
      .learn_box {
        flex-direction: column;
        .learn_left {
          width: 90%;
          margin-right: 0px;
          margin-bottom: 20px;
        }
        .learn_right {
          width: 90%;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .home {
    .carousel_container {
      .home_swiper {
        .title_content {
          margin: 120px auto 175px;
          h1 {
            font-size: 40px;
          }
        }
      }
    }
    .card_container {
      .card_box {
        .card {
          flex-wrap: wrap;
          justify-content: space-around;
          margin: 40px 0 100px;
          li {
            width: 90%;
            margin-bottom: 20px;
          }
        }
      }
    }
    .learn_container {
      .learn_box {
        padding: 70px 0;
      }
    }
    .project_container {
      .server_swiper_box {
        .project_swiper {
          .swiper-slide {
            width: 100vw;
          }
        }
      }
    }
  }
}
</style>
<!--自定义分页器样式-->
<style></style>
