<template>
      <div class="serve_container">
        <div class="serve_box border_text_box container_width">
          <h2 class="border_text">Our Service</h2>
          <h3 class="border_text_title">我们的服务</h3>
          <div class="server_text">消费者为王。这就是数字经济的现实。对于品牌而言，影响深远。 最终，他们必须以新的方式与消费者互动，同时根据快速变化的需求，态度和行为重新定制他们的服务。</div>
          <ul class="server_list_box">
            <li v-bind:class="{ service_card_in1: serviceflag }">
              <div class="server_card_img">
                <div class="server_img_box">
                  <i class="iconfont icon-a-youjianchakanyoujianfasongyoujianshouyoujian-05"></i>
                </div>
              </div>
              <div class="server_card_title">全自动广告投放系统</div>
              <div class="server_card_text">这是一个企业级的开箱即用的爬虫管理平台，包含很多针对爬虫调度监控的实用功能，例如节点注册发现、任务调度、文件编辑、结果查看、日志管理等。</div>
            </li>
            <li v-bind:class="{ service_card_in2: serviceflag }">
              <div class="server_card_img">
                <div class="server_img_box">
                  <i class="iconfont icon-mubiao"></i>
                </div>
              </div>
              <div class="server_card_title">跟踪域管理系统</div>
              <div class="server_card_text">通过对抓获的域名链接进行分析，可以得到有用的信息。清楚链接的来源跟去向及上下层网站的关系。</div>
            </li>
            <li v-bind:class="{ service_card_in3: serviceflag }">
              <div class="server_card_img">
                <div class="server_img_box">
                  <i class="iconfont icon-w_diannao"></i>
                </div>
              </div>
              <div class="server_card_title">Yoyumob网盟</div>
              <div class="server_card_text">Yoyumob是一家效果营销投放公司，致力于通过桌面和移动端的多种营销渠道， 以品牌推广、潜在客户生成和用户获取方式，为广告客户提供高质量流量。</div>
              </li>
            <li v-bind:class="{ service_card_in4: serviceflag }">
              <div class="server_card_img">
                <div class="server_img_box">
                  <i class="iconfont icon-jingbao_alarm"></i>
                </div>
              </div>
              <div class="server_card_title">大数据指纹监测系统</div>
              <div class="server_card_text">我们的使命是通过准确有效的广告欺诈缓解措施来改善营销结果，从而提高客户的增长速度。我们与客户勤奋合作，通过创新改变游戏规则的技术来防止欺诈者获得营销收益。</div>
              </li>
          </ul>
        </div>
      </div>
</template>

<script>
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
export default {
  name: "service-card",
  data() {
      return {
        serviceflag: false,
      }
  },
  mounted(){
      //获取页面可视化高度
     window.addEventListener("scroll",()=>{ 
      //获取页面滚动的高度
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollTop > 3500) {
          this.serviceflag = true
        }
     }) 
  },
}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
@import '../assets/iconfont/iconfont.css';

.serve_container {
    background: #f7f7f7;
    .serve_box {
        padding: 125px 0 225px;
        text-align: center;
        .server_text {
            margin: 0 auto 145px;
            font-size: 16px;
            color: #464949;
            line-height: 24px;
        }
        .server_list_box {
            @include flexrl();
            align-items: flex-start;
            li {
                opacity: 0;
                width: 20%;
                @include flextop();
                animation-fill-mode: both;
                .server_card_img {
                    display: inline-block;
                    transition: transform 0.3s linear, border-image 0.3s linear;
                    width: 140px;
                    height: 140px;
                    border: 1px #000 solid;
                    margin-bottom: 50px;
                    .server_img_box {
                        @include flexcenter();
                        width: 100%;
                        height: 100%;
                        transition: transform 0.3s linear;
                        i {
                            font-size: 48px;
                        }
                    }
                    .server_img_box:hover {
                        transform: rotate(45deg);
                        i {
                            -webkit-background-clip: text;
                            color: transparent;
                            background-image: linear-gradient(99deg, #73e0a9, #5b68df 100%);
                        }
                    }
                }
                .server_card_img:hover {
                    transform: rotate(-45deg);
                    border-image: linear-gradient(135deg, #73e0a9, #5b68df 100%) 1 1;
                }
                .server_card_img:hover+.server_card_title {
                    -webkit-background-clip: text;
                    color: transparent;
                    background-image: linear-gradient(99deg, #73e0a9, #5b68df 100%);
                }
                .server_card_title {
                    margin-bottom: 25px;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 36px;
                    position: relative;
                }
                .server_card_title::after {
                    content: '';
                    position: absolute;
                    width: 49px;
                    height: 0px;
                    border: 2px #c5c5c5 solid;
                    border-radius: 20px;
                    bottom: -5px;
                    right: 50%;
                    transform: translateX(50%);
                }
                .server_card_text {
                    font-size: 13px;
                    text-align: justify;
                    color: #464949;
                    line-height: 20px;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .serve_container {
        .serve_box {
            padding: 40px 0 40px;
            .server_text {
                margin: 0 auto 50px;
            }
            .server_list_box {
                flex-wrap: wrap;
                li {
                    width: 45%;
                    margin-bottom: 30px;
                    .server_card_img {
                      width: 120px;
                      height: 120px;
                    }
                }
            }
        }
    }
}
// 服务卡片进场动画
.service_card_in1 {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
  animation-fill-mode: both;
}
.service_card_in2 {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}
.service_card_in3 {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}
.service_card_in4 {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
  animation-delay: 0.8s;
  animation-fill-mode: both;
}
</style>